/**
 * Slides Custom CSS
 */
@import url(https://fonts.googleapis.com/css?family=Varela+Round);

.opacity:not(.active) {
    opacity: 0;
}

.staffs:not(.active) {
  opacity: 0.01;
}

.circle#title,
.circle {
    background: #ffeb3b;
    line-height: 1.1;
    color: black;
    padding: 10rem  5rem;
    border-radius: 4% 50% 0% 70%;
    box-shadow: rgb(191 197 202) 10px 10px, rgba(0,0,0,0.3) 30px 30px;
}

#starter {
  background: black;
  color: hsla(0, 0%, 0%, .9);
  left: 0;
  letter-spacing: 5px;
  position: absolute;
  text-align: center;
  font: normal 140px Varela Round, sans-serif;
  text-transform: uppercase;
  animation: move linear 2000ms infinite;
}

#starter p {
  margin-top: 14rem;
}

.impress-enabled .step .btn-block {
  color: hsla(0,0%,100%,.8);
  text-decoration: none;
  padding: 0 .4em;
  background: #3e4852;
  border-radius: .2em;
  transition: all .5s;
}

.impress-enabled .step:not(.active) {
  z-index: -1;
}

.impress-enabled .step.active {
  z-index: 9999;
}

.step h2 {
  line-height: 0.8;
  font-weight: bold;
}

.step h2 > strong {
  font-size: 50%;
  font-weight: normal;
}

.step.large-title h2 {
  font-size: 10rem;
  font-weight: bold;
}

.stepbox {
    position: relative;
    max-width: 600px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
    transition: all 0.3s;
    color: #161830;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .stepbox--large {
    max-width: 900px;
    height: 550px;
  }

  .em-1 { position: absolute; left: 10%; top:14%;}
  .em-2 { position: absolute; left: 22%; top:62%;}
  .em-3 { position: absolute; left: 87%; top:30%;}
  .em-4 { position: absolute; left: 65%; top:55%;}

@keyframes move {
  0% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  25% {
    text-shadow:
      -0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1),
      -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1),
      0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1),
      2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  50% {
    text-shadow:
      -0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1),
      -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1),
      0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1),
      2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  75% {
    text-shadow:
      0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1),
      2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1),
      -0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1),
      -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  100% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
}

.brand {
  line-height: 1.5;
  background: #161830;
  font-size: 3rem;
}
.brand p {
    margin: 5rem 0 2rem;
}
.brand h2 {
  text-shadow: none;
  background: linear-gradient(90deg, #a2763d, #fbf3a4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10rem;
  font-weight: bold;
  text-transform: uppercase;
}

.brand img {
  max-width: 200px;
  margin: 3rem auto 0;
}

.tgif {
  display: inline;
  color: black;
  font-size: 100%;
  font-family:Varela Round, sans-serif;
  text-transform: uppercase;
  animation: move linear 2000ms infinite;
}

.slide {
  width: 1400px;
  height: 800px;
}

.rc-progress-line {
  height: auto;
}

.rc-progress-line-path,
.rc-progress-line-trail {
  stroke-width: 5
}