@import url(https://fonts.googleapis.com/css?family=Varela+Round);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  background: linear-gradient(to right, #ffa751, #ffe259);
  min-height: calc(100vh - 6rem);
  width: calc(100% - 6rem);
  color: #444;
  text-align: left;
  padding: 3rem;
}

.page-card {
  max-width: 45rem;
  height: 40vh;
  margin: 24vh auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0 0 2rem;
  font-size: 4rem;
  text-align: center;
  font-family: Varela Round, sans-serif;
}

.btn-wrap {
  margin: 0 auto;
  display: flex;
  max-width: 25rem;
  padding: 2rem;
  justify-content: space-between;
}
.pushable {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  min-width: 10rem;
  text-decoration: none;
  font-weight: bold;
  font-family: Varela Round, sans-serif;
  text-align: center;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  transform: translateY(2px);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(340deg 100% 32%);
}
.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  background: hsl(345deg 100% 47%);
  transform: translateY(-4px);
}

.sec .front {
  background: hsl(211deg 59% 12%)
}
.sec .edge {
  background-color: black;
}

.pushable:hover .front {
  transform: translateY(-6px);
}
.pushable:hover .shadow {
  transform: translateY(4px);
}
.pushable:active .front {
  transform: translateY(-2px);
}
.pushable:active .shadow {
  transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}