@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  background: linear-gradient(to right, #ffa751, #ffe259);
  min-height: calc(100vh - 6rem);
  width: calc(100% - 6rem);
  color: #444;
  text-align: left;
  padding: 3rem;
}

.page-card {
  max-width: 45rem;
  height: 40vh;
  margin: 24vh auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.title {
  margin: 0 0 2rem;
  font-size: 4rem;
  text-align: center;
  font-family: Varela Round, sans-serif;
}

.btn-wrap {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  max-width: 25rem;
  padding: 2rem;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pushable {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
  min-width: 10rem;
  text-decoration: none;
  font-weight: bold;
  font-family: Varela Round, sans-serif;
  text-align: center;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(340deg 100% 32%);
}
.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  background: hsl(345deg 100% 47%);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.sec .front {
  background: hsl(211deg 59% 12%)
}
.sec .edge {
  background-color: black;
}

.pushable:hover .front {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}
.pushable:hover .shadow {
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}
.pushable:active .front {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.pushable:active .shadow {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}
/**
 * Slides Custom CSS
 */

.opacity:not(.active) {
    opacity: 0;
}

.staffs:not(.active) {
  opacity: 0.01;
}

.circle#title,
.circle {
    background: #ffeb3b;
    line-height: 1.1;
    color: black;
    padding: 10rem  5rem;
    border-radius: 4% 50% 0% 70%;
    box-shadow: rgb(191 197 202) 10px 10px, rgba(0,0,0,0.3) 30px 30px;
}

#starter {
  background: black;
  color: hsla(0, 0%, 0%, .9);
  left: 0;
  letter-spacing: 5px;
  position: absolute;
  text-align: center;
  font: normal 140px Varela Round, sans-serif;
  text-transform: uppercase;
  -webkit-animation: move linear 2000ms infinite;
          animation: move linear 2000ms infinite;
}

#starter p {
  margin-top: 14rem;
}

.impress-enabled .step .btn-block {
  color: hsla(0,0%,100%,.8);
  text-decoration: none;
  padding: 0 .4em;
  background: #3e4852;
  border-radius: .2em;
  transition: all .5s;
}

.impress-enabled .step:not(.active) {
  z-index: -1;
}

.impress-enabled .step.active {
  z-index: 9999;
}

.step h2 {
  line-height: 0.8;
  font-weight: bold;
}

.step h2 > strong {
  font-size: 50%;
  font-weight: normal;
}

.step.large-title h2 {
  font-size: 10rem;
  font-weight: bold;
}

.stepbox {
    position: relative;
    max-width: 600px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
    transition: all 0.3s;
    color: #161830;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
  }

  .stepbox--large {
    max-width: 900px;
    height: 550px;
  }

  .em-1 { position: absolute; left: 10%; top:14%;}
  .em-2 { position: absolute; left: 22%; top:62%;}
  .em-3 { position: absolute; left: 87%; top:30%;}
  .em-4 { position: absolute; left: 65%; top:55%;}

@-webkit-keyframes move {
  0% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  25% {
    text-shadow:
      -0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1),
      -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1),
      0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1),
      2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  50% {
    text-shadow:
      -0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1),
      -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1),
      0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1),
      2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  75% {
    text-shadow:
      0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1),
      2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1),
      -0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1),
      -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  100% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
}

@keyframes move {
  0% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  25% {
    text-shadow:
      -0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px -3px 0 hsla(180, 100%, 50%, 1),
      -2px -2px 0 hsla(180, 100%, 50%, 1),
      -1px -1px 0 hsla(180, 100%, 50%, 1),
      0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      3px 3px 0 hsla(0, 100%, 50%, 1),
      2px 2px 0 hsla(0, 100%, 50%, 1),
      1px 1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  50% {
    text-shadow:
      -0.1rem 0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px 3px 0 hsla(0, 100%, 50%, 1),
      -2px 2px 0 hsla(0, 100%, 50%, 1),
      -1px 1px 0 hsla(0, 100%, 50%, 1),
      0.1rem -0.1rem 0 hsla(180, 100%, 50%, 1),
      3px -3px 0 hsla(180, 100%, 50%, 1),
      2px -2px 0 hsla(180, 100%, 50%, 1),
      1px -1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  75% {
    text-shadow:
      0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      3px 3px 0 hsla(180, 100%, 50%, 1),
      2px 2px 0 hsla(180, 100%, 50%, 1),
      1px 1px 0 hsla(180, 100%, 50%, 1),
      -0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      -3px -3px 0 hsla(0, 100%, 50%, 1),
      -2px -2px 0 hsla(0, 100%, 50%, 1),
      -1px -1px 0 hsla(0, 100%, 50%, 1)
    ;
  }
  100% {
    text-shadow:
      0.1rem -0.1rem 0 hsla(0, 100%, 50%, 1),
      3px -3px 0 hsla(0, 100%, 50%, 1),
      2px -2px 0 hsla(0, 100%, 50%, 1),
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -0.1rem 0.1rem 0 hsla(180, 100%, 50%, 1),
      -3px 3px 0 hsla(180, 100%, 50%, 1),
      -2px 2px 0 hsla(180, 100%, 50%, 1),
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
}

.brand {
  line-height: 1.5;
  background: #161830;
  font-size: 3rem;
}
.brand p {
    margin: 5rem 0 2rem;
}
.brand h2 {
  text-shadow: none;
  background: linear-gradient(90deg, #a2763d, #fbf3a4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 10rem;
  font-weight: bold;
  text-transform: uppercase;
}

.brand img {
  max-width: 200px;
  margin: 3rem auto 0;
}

.tgif {
  display: inline;
  color: black;
  font-size: 100%;
  font-family:Varela Round, sans-serif;
  text-transform: uppercase;
  -webkit-animation: move linear 2000ms infinite;
          animation: move linear 2000ms infinite;
}

.slide {
  width: 1400px;
  height: 800px;
}

.rc-progress-line {
  height: auto;
}

.rc-progress-line-path,
.rc-progress-line-trail {
  stroke-width: 5
}

.inbox {
	max-width: 25rem;
	margin: 0 auto;
	max-height: calc(100vh - 24rem);
	background: white;
	border-radius: 5px;
	box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1);
	overflow: auto;
}

.list-view .item {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	border-bottom: 1px solid #F1F1F1;
}

.list-view .item:last-child {
	border-bottom: 0;
}

.list-view .btn-remove{
	margin: 20px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
	overflow: auto;
	font-size: 1.2rem;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.list-view label {
	margin: 0;
	padding: 20px;
	transition: all 0.2s;
	-webkit-flex: 1 1;
	        flex: 1 1;
	font-family: 'helvetica neue';
	font-size: 20px;
	font-weight: 200;
	border-right: 1px solid #D1E2FF;
	cursor: pointer;
}

.list-view .item:hover {
	background: #fafafa;
}

.item:hover .btn-remove {
	opacity: 1;
}

body {
  color: #e4f5ff;
  -webkit-font-smoothing: antialiased;
}
.App {
  text-align: center;
}

#react-impressjs {
  background-image: linear-gradient(to top, #00c6fb 0%, #005bea 100%);
}

.pinned {
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  font-size: 3rem;
  text-decoration: none;
}

.impress-enabled .hint,
.impress-enabled .mobile-hint {
  z-index: 999;
}
