
.inbox {
	max-width: 25rem;
	margin: 0 auto;
	max-height: calc(100vh - 24rem);
	background: white;
	border-radius: 5px;
	box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.1);
	overflow: auto;
}

.list-view .item {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #F1F1F1;
}

.list-view .item:last-child {
	border-bottom: 0;
}

.list-view .btn-remove{
	margin: 20px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
	overflow: auto;
	font-size: 1.2rem;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.list-view label {
	margin: 0;
	padding: 20px;
	transition: all 0.2s;
	flex: 1;
	font-family: 'helvetica neue';
	font-size: 20px;
	font-weight: 200;
	border-right: 1px solid #D1E2FF;
	cursor: pointer;
}

.list-view .item:hover {
	background: #fafafa;
}

.item:hover .btn-remove {
	opacity: 1;
}
